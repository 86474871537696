import React, { ReactNode } from 'react';
import SanityPicture from 'components/SanityPicture';

export type ColorOfTheYearProps = {
  description: ReactNode;
  image: {
    alt?: string;
    url: string;
  };
  title: ReactNode;
};

const ColorOfTheYear = ({ description, image, title }: ColorOfTheYearProps) => (
  <div className={'container mb-32 mt-64 items-center'}>
    <div className="m-auto max-w-[276px] sm:flex sm:max-w-[700px] sm:flex-row">
      <SanityPicture
        hero
        className="aspect-ratio-5by7"
        url={image.url}
        alt={image.alt || 'Pantone Color Of The Year'}
      />

      <div className="bg-black p-32 text-white">
        <div className="block">
          <div className={'text-h2 mb-8 text-white'}>{title}</div>

          <hr className="h-6 my-12 bg-white" />
        </div>

        {description}
      </div>
    </div>
  </div>
);

export default ColorOfTheYear;
