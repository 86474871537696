import Link from 'next/link';
import { useEffect, useState } from 'react';

import { SubNav, Link as SanityLink, Navigation } from '../../../sanity/schema-types';

type NavItemsProps = {
  pathname: string;
  navSection: SubNav;
};

const NavItems = ({ pathname, navSection }: NavItemsProps) => {
  return (
    <div className="bg-white p-16 pl-16 sm:pl-[80px] md:pl-[104px]">
      <ul className="m-0 list-none p-0 sm:flex">
        {navSection.subNavSectionBuilder?.map((m: SanityLink) => (
          <li key={m.title}>
            <Link href={`${m.href}`} passHref>
              <a
                className={`${pathname === m.href ? 'text-black' : 'text-gray-dark'} mr-16 text-sm ${
                  m.trackerClass ?? ''
                }`}
              >
                {m.title}
              </a>
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
};

type NavSubProps = {
  navigation: Navigation;
  pathname: string;
};

const NavSub = ({ pathname, navigation }: NavSubProps) => {
  const [navSection, setNavSection] = useState<SubNav>();

  useEffect(() => {
    setNavSection(navigation?.navigationBuilder?.find((section: SubNav) => pathname.includes(`${section.href}`)));
  }, [pathname]);

  if (navSection?.subNavSectionBuilder?.length) {
    return (
      <nav>
        <div className="hidden sm:block">
          <NavItems pathname={pathname} navSection={navSection} />
        </div>
      </nav>
    );
  } else {
    return <></>;
  }
};

export default NavSub;
