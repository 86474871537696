import React from 'react';
import SalesCallout from 'components/SalesCallout';

type SalesCalloutSwatchesProps = {
  className?: string;
  ctaPageName: string;
};

const SalesCalloutSwatches = ({ className, ctaPageName }: SalesCalloutSwatchesProps) => {
  return (
    <SalesCallout
      calloutId="sales_callout_swatches"
      className={className ? className : ''}
      ctaPageName={ctaPageName}
      ctaTitle="See Offer"
      href="/swatches"
      title="Free Swatches"
      image={
        <div className="flex max-w-full" style={{ aspectRatio: '276 / 196' }}>
          <picture>
            <img
              className="block h-auto max-w-full align-middle"
              loading="lazy"
              src="https://gentux.imgix.net/1666925267_1663789977MGSiteOfferCalloutsSwatches.jpeg?w=276&dpr=2"
              alt="Blue Menguin swatches against a blue fabric"
            />
          </picture>
        </div>
      }
    >
      Need help with color matching? We'll send you complimentary swatches.
    </SalesCallout>
  );
};

export default SalesCalloutSwatches;
