import React, { PropsWithChildren } from 'react';
import Link from 'next/link';

export type FeaturedLinksItem = {
  copy: string;
  image: SimpleImage;
  url: string;
};

export type FeaturedLinksProps = PropsWithChildren<{
  items: FeaturedLinksItem[];
}>;

const FeaturedLinks = ({ children, items }: FeaturedLinksProps) => (
  <div className="p-section bg-panel-5/12-to-right">
    <div className="container space-y-32">
      {children}

      <div className="gap-32 space-y-32 md:flex md:space-y-0">
        {items?.length > 0 &&
          items.map((item) => (
            <Link href={item.url} key={item.url}>
              <a className="group block space-y-16 bg-white p-32 shadow-3">
                <img alt={item.image.alt || ''} loading="lazy" src={`${item.image.url}?w=100`} width={100} />
                <p className="text-blue-dark group-hover:underline">{item.copy}</p>
              </a>
            </Link>
          ))}
      </div>
    </div>
  </div>
);

export default FeaturedLinks;
