const theme = require('./src/tailwind/tailwind-theme-menguin.js');
const plugin = require('tailwindcss/plugin');
const _ = require('lodash');
const safelist = require('./src/tailwind/safelist.js');

module.exports = {
  mode: 'jit',
  purge: {
    content: ['./pages/**/*.{js,ts,jsx,tsx}', './src/**/*.{js,ts,jsx,tsx}', './sanity/static/styleOptions.js'],
    safelist: safelist,
  },
  theme: { ...theme },
  darkMode: 'media',
  variants: {
    extend: {
      cursor: ['disabled'],
      backgroundColor: ['disabled'],
      borderColor: ['disabled'],
      boxShadow: ['disabled'],
      placeholderColor: ['disabled'],
      filter: ['hover'],
      aspectRatioUtilities: ['responsive'],
    },
  },
  plugins: [
    require('@tailwindcss/aspect-ratio'),
    require('./src/tailwind/typography-menguin'),
    plugin(function ({ addUtilities, theme, e }) {
      const aspectRatioUtilities = _.map(theme('aspectRatios'), (value, key) => {
        return {
          [`.${e(`aspect-ratio-${key}`)}`]: {
            'aspect-ratio': value,
          },
        };
      });

      addUtilities(aspectRatioUtilities);
    }),
  ],
};
