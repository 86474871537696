import React from 'react';
import Line from 'components/Line';

interface FaqGroupProps {
  title?: string;
}

type Props = React.FC<React.HTMLProps<HTMLDivElement> & FaqGroupProps>;

const FaqGroup: Props = ({ title, className, children, ...props }) => {
  return (
    <div {...props} className={`${className ? className : ''}`}>
      <div>
        {title && (
          <>
            <h2 className="text-h2">{title}</h2>
            <Line className="mb-0 sm:mb-0" />
          </>
        )}

        <div>{children}</div>
      </div>
    </div>
  );
};

export default FaqGroup;
