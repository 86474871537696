import { useEffect } from 'react';

/**
 * Custom React hook that locks or unlocks body scroll based on the provided condition.
 * It also handles touch events on mobile devices to prevent scrolling when the body scroll is locked.
 *
 * @param lockCondition - A boolean that determines whether the body scroll should be locked.
 *                                   `true` to lock scroll, `false` to unlock.
 */
export default function useLockBodyScroll(lockCondition: boolean) {
  useEffect(() => {
    if (lockCondition) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'initial';
    }

    // Optionally handle mobile touch move events
    const preventTouchMove = (ev: TouchEvent) => ev.preventDefault();
    if (lockCondition) {
      document.body.addEventListener('touchmove', preventTouchMove, { passive: false });
    }

    return () => {
      document.body.style.overflow = 'initial';
      document.body.removeEventListener('touchmove', preventTouchMove);
    };
  }, [lockCondition]);
}
