const plugin = require('tailwindcss/plugin');

module.exports = plugin(function ({ addBase, theme }) {
  const typography = {
    '.btn': {
      fontSize: '12px',
      letterSpacing: '0.63px',
      lineHeight: 1.5,
      fontFamily: theme('fontFamily.display'),
    },
    '.btn-sm': {
      fontSize: '10px',
      letterSpacing: '0.56px',
      lineHeight: 1.12,
    },
    '.text-h1, .prose h1, .text-h2, .prose h2, .text-h3, .prose h3, .text-h4, .prose h4, .text-h5, .prose h5, .text-h6, .prose h6':
      {
        fontFamily: theme('fontFamily.condensed'),
        color: theme('colors.black'),
        textTransform: 'uppercase',
      },
    '.text-h1, .prose h1': {
      fontSize: '38px',
      [`@media (min-width: ${theme('screens.md')})`]: {
        fontSize: '65px',
      },
      lineHeight: 1.1,
      letterSpacing: '0.02em',
    },
    '.text-h2, .prose h2': {
      fontSize: '29px',
      [`@media (min-width: ${theme('screens.md')})`]: {
        fontSize: '34px',
      },
      lineHeight: 1.1,
      letterSpacing: '0.04em',
    },
    '.text-h3, .prose h3': {
      fontSize: '24px',
      lineHeight: 1.3,
      letterSpacing: '0.054em',
    },
    '.text-h4, .prose h4': {
      fontSize: '20px',
      lineHeight: 1.3,
      letterSpacing: '0.065em',
    },
    '.text-h5, .prose h5': {
      fontSize: '16px',
      lineHeight: 1.3,
      letterSpacing: '0.08em',
    },
    '.text-h6, .prose h6': {
      fontSize: '13px',
      lineHeight: 1.4,
      letterSpacing: '0.1em',
    },

    '.text-h1.normal-case, .text-h1.lowercase': {
      letterSpacing: '0.008em',
    },
    '.text-h2.normal-case, .text-h2.lowercase': {
      letterSpacing: '0.018em',
    },
    '.text-h3.normal-case, .text-h3.lowercase': {
      letterSpacing: '0.038em',
    },
    '.text-h4.normal-case, .text-h4.lowercase': {
      letterSpacing: '0.04em',
    },
    '.text-h5.normal-case, .text-h5.lowercase': {
      letterSpacing: '0.05em',
    },
    '.text-h6.normal-case, .text-h6.lowercase': {
      letterSpacing: '0.06em',
    },

    '.text-h1-display, .text-h2-display, .text-h3-display, .text-h4-display, .text-h5-display, .text-h6-display': {
      fontFamily: theme('fontFamily.display'),
      color: theme('colors.black'),
    },
    '.text-h1-display': {
      fontSize: '27px',
      [`@media (min-width: ${theme('screens.md')})`]: {
        fontSize: '39px',
      },
      lineHeight: 1.3,
    },
    '.text-h2-display': {
      fontSize: '23px',
      [`@media (min-width: ${theme('screens.md')})`]: {
        fontSize: '26px',
      },
      lineHeight: 1.3,
    },
    '.text-h3-display': {
      fontSize: '19px',
      [`@media (min-width: ${theme('screens.md')})`]: {
        fontSize: '21px',
      },
      lineHeight: 1.35,
    },
    '.text-h4-display': {
      fontSize: '16px',
      lineHeight: 1.4,
      letterSpacing: '0.03em',
    },
    '.text-h5-display': {
      fontSize: '13px',
      lineHeight: 1.5,
      letterSpacing: '0.05em',
    },
    '.text-h6-display': {
      fontSize: '10px',
      lineHeight: 1.5,
      letterSpacing: '0.06em',
    },

    '.text-lg': {
      fontSize: '22px',
      lineHeight: 1.3,
    },
    '.text-sm': {
      fontSize: '12.8px',
      lineHeight: 1.6,
      letterSpacing: '0.016em',
    },
    '.text-xs': {
      fontSize: '10.24px',
      lineHeight: 1.66,
      letterSpacing: '0.03em',
    },
  };

  addBase(typography);
});
