import { JsonResponse, SeoData } from '../types';
import { getLocalURL } from '../utils/utils';

export const fetchSEO = async (slug: string): Promise<JsonResponse<SeoData>> =>
  await fetch(`${getLocalURL()}/api/seo`, {
    method: 'POST',
    body: JSON.stringify({
      slug,
    }),
    headers: {
      'Content-Type': 'application/json',
    },
  });
