import React from 'react';

interface QuoteProps {
  citationName?: String;
  citationTitle?: String;
}

type Props = React.FC<React.BlockquoteHTMLAttributes<HTMLQuoteElement> & QuoteProps>;

const Quote: Props = ({ citationName, citationTitle, className, children, ...props }) => {
  return (
    <blockquote {...props} className={`space-y-16 sm:space-y-32 ${className ? className : ''}`}>
      <div
        className="
          relative
          border-gray-dark
          font-display
          text-lg
          text-gray-darker
          before:block
          before:text-5xl
          before:leading-none
          before:text-blue
          before:content-['\201C']
        "
      >
        {children}
      </div>

      <div className="flex flex-col items-end">
        <div
          className="
            relative
            flex
            items-center
          "
        >
          <div>
            {citationName && <div className="text-h4">{citationName}</div>}

            {citationTitle && <p className="text-gray">{citationTitle}</p>}
          </div>
        </div>
      </div>
    </blockquote>
  );
};

export default Quote;
