import { Breakpoints } from 'types';
import tw from '@root/tailwind.config';

interface ChunkedClassNames {
  match: string[];
  noMatch: string[];
}

const parseArClass = (className: string): string => {
  return className.split('-').slice(-1)[0].replace('by', ':');
};

const classNamesBy = (classes: string, chunkBy: string): ChunkedClassNames => {
  let classNameArray = classes.split(' ');

  return {
    match: classNameArray.filter((item) => item.includes(chunkBy)),
    noMatch: classNameArray.filter((item) => !item.includes(chunkBy)),
  };
};

const getBaseClass = (classes: string, by: string, fallback: string): string | undefined =>
  classes.split(' ').filter((x) => !x.includes(':') && x.includes(by))[0] || fallback;

const addBaseToScreens = (screens: object): [string, string][] => {
  const screenArray: [string, string][] = [...Object.entries(screens), ['base', '0px']];
  const sortedScreensArray: [string, string][] = screenArray.sort(
    (a, b) => Number(a[1].replace(/[A-Za-z%]+/, '')) - Number(b[1].replace(/[A-Za-z%]+/, ''))
  );

  return sortedScreensArray;
};

const findPrevValue = (index: number, screens: [string, string][], classes: string[]): string => {
  if (index === 0) return classes.filter((x) => !x.includes(':')).map((x) => parseArClass(x))[0];

  const hasPrevValue = screens[index - 1][0] && classes.filter((x) => x.includes(screens[index - 1][0])).length > 0;
  const prevValue = classes.filter((x) => x.includes(screens[index - 1][0])).map((x) => parseArClass(x))[0];

  return hasPrevValue ? prevValue : findPrevValue(index - 1, screens, classes);
};

const mapClassNamesToScreens = (screens: object, classes: string[], baseClass: string) => {
  const screenArray = addBaseToScreens(screens);
  const base = getBaseClass(classes.join(' '), 'aspect-ratio', baseClass);
  const classesWithBase = !base ? classes : [...classes, `base:${base}`];

  return screenArray.reduce((acc, val, index) => {
    const currentVal = classesWithBase.filter((x) => x.includes(val[0])).map((x) => parseArClass(x))[0];
    const prevValue = findPrevValue(index, screenArray, classesWithBase);

    return {
      ...acc,
      [val[0]]: currentVal || prevValue || '',
    };
  }, {});
};

type PictureAttributes = {
  aspectRatios: Breakpoints;
  chunkedClasses: ChunkedClassNames;
  className: (string | undefined)[];
  hasValidAr: boolean;
  screens: Breakpoints;
};

export const buildPictureAttributes = (
  className: string,
  aspectRatioBaseClass = 'aspect-ratio-7by5'
): PictureAttributes => {
  const chunkedClasses = classNamesBy(className, 'aspect-ratio');
  const baseClass = getBaseClass(chunkedClasses.match.join(' '), 'aspect-ratio', 'aspect-ratio-7by5');
  const hasValidAr = chunkedClasses.match.reduce((acc, val) => {
    const aspectRatio = val.split('-').slice(-1)[0].replace('by', ':');

    return aspectRatio && aspectRatio.replace(':', 'by') in tw.theme.aspectRatios ? acc : false;
  }, true);

  return {
    aspectRatios: mapClassNamesToScreens(tw.theme.screens, chunkedClasses.match, aspectRatioBaseClass),
    chunkedClasses,
    className: [...new Set([...chunkedClasses.match, baseClass])],
    hasValidAr,
    screens: tw.theme.screens,
  };
};

export { parseArClass, classNamesBy, getBaseClass, findPrevValue, addBaseToScreens, mapClassNamesToScreens };
