import React from 'react';

interface IconInstagramProps {}

type Props = React.FC<React.HTMLAttributes<HTMLOrSVGElement> & IconInstagramProps>;

const IconInstagram: Props = ({ className, ...props }) => {
  return (
    <svg
      viewBox="0 0 16 16"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      className={`h-16 w-16 fill-current text-black ${className ? className : ''}`}
    >
      <title>Instagram logo</title>
      <path d="M8 1.418h3.24c.81 0 1.216.202 1.52.304.405.1.607.303.91.607.305.304.507.506.608.912.102.303.203.708.304 1.519v6.48c0 .81-.202 1.216-.304 1.52-.1.405-.303.607-.607.91-.304.305-.506.507-.912.608-.303.102-.708.203-1.518.304H4.76c-.81 0-1.216-.202-1.52-.304-.405-.1-.607-.303-.91-.607-.305-.304-.507-.506-.609-.912-.1-.303-.202-.708-.303-1.518V8 4.76c0-.81.202-1.216.304-1.52.1-.405.303-.607.607-.91.304-.305.506-.507.911-.608.304-.102.71-.203 1.52-.304H8zM8 0H4.658C3.747 0 3.24.203 2.734.405a4.248 4.248 0 00-1.418.911 4.248 4.248 0 00-.91 1.418C.202 3.241.1 3.848 0 4.658v6.684c0 .911.203 1.417.405 1.924.203.506.506 1.012.911 1.418.406.405.912.708 1.418.91.506.204 1.114.305 1.924.406h6.684c.911 0 1.417-.203 1.924-.405a4.248 4.248 0 001.418-.911c.405-.405.708-.912.91-1.418.204-.507.305-1.114.406-1.924V8 4.658c0-.911-.203-1.417-.405-1.924a4.248 4.248 0 00-.911-1.418 4.248 4.248 0 00-1.418-.91C12.759.202 12.152.1 11.342 0H8z" />
      <path d="M8 3.95c-2.33 0-4.152 1.822-4.152 4.151 0 2.33 1.823 4.152 4.152 4.152 2.33 0 4.152-1.823 4.152-4.152S10.329 3.95 8 3.95zm0 6.885a2.723 2.723 0 01-2.734-2.734A2.723 2.723 0 018 5.367a2.723 2.723 0 012.734 2.734A2.723 2.723 0 018 10.835zM12.354 4.76a1.013 1.013 0 100-2.026 1.013 1.013 0 000 2.026z" />
    </svg>
  );
};

export default IconInstagram;
