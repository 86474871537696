import IconFacebook from 'components/IconFacebook';
import IconInstagram from 'components/IconInstagram';
import IconPinterest from 'components/IconPinterest';
import IconTwitter from 'components/IconTwitter';
import { useEffect, useState } from 'react';

type SocialMediaSite = 'facebook' | 'pinterest' | 'twitter' | 'instagram';

type Props = {
  site: SocialMediaSite;
  url: string;
};

type IconProps = {
  site: SocialMediaSite;
  className: string;
};

const siteToIcon = {
  facebook: IconFacebook,
  instagram: IconInstagram,
  twitter: IconTwitter,
  pinterest: IconPinterest,
};

const SocialMediaIcon = ({ site, ...props }: IconProps) => {
  const Icon = siteToIcon[site];

  return <Icon {...props} />;
};

const getAttributesBySiteName = (site: SocialMediaSite): { [key: string]: string } => {
  switch (site) {
    case 'instagram':
      return {};
    case 'pinterest':
      return {
        'data-pin-log': 'button_follow',
      };
    case 'twitter':
      return {
        'data-show-count': 'false',
        'data-size': 'large',
        'data-show-screen-name': 'false',
        'data-dnt': 'true',
      };
    case 'facebook':
      return {
        'data-href': 'https://www.facebook.com/menguintux',
        'data-layout': 'standard',
        'data-action': 'like',
        'data-show-faces': 'true',
        'data-share': 'true',
      };
  }
};

const SocialMediaLink = ({ site, url }: Props) => {
  const [dataAttributes, setDataAttributes] = useState(() => getAttributesBySiteName(site));

  useEffect(() => {
    setDataAttributes(getAttributesBySiteName(site));
  }, [site]);

  return (
    <a href={url} id={`follow-${site}`} target="_blank" {...dataAttributes}>
      <SocialMediaIcon site={site} className="!text-blue-dark hover:!text-black" />
    </a>
  );
};

export default SocialMediaLink;
