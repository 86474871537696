import React from 'react';
import SalesCallout from 'components/SalesCallout';

type SalesCalloutInspirationProps = {
  className?: string;
  ctaPageName: string;
};

const SalesCalloutInspiration = ({ className, ctaPageName }: SalesCalloutInspirationProps) => {
  return (
    <SalesCallout
      calloutId="sales_callout_inspiration"
      className={className ? className : ''}
      ctaPageName={ctaPageName}
      ctaTitle="Get Inspired"
      href="/gallery"
      title="Need Inspiration?"
      image={
        <div className="flex max-w-full" style={{ aspectRatio: '276 / 202' }}>
          <picture>
            <img
              className="block h-auto max-w-full align-middle"
              loading="lazy"
              src="https://gentux.imgix.net/1638204767_1637696804MGSiteModuleInspo1cropped.jpg?w=276&dpr=2"
              alt="Grid of wedding photos featuring grooms wearing menguin suits and tuxes"
            />
          </picture>
        </div>
      }
    >
      We've got style. Take a look at our suits and tuxes in action.
    </SalesCallout>
  );
};

export default SalesCalloutInspiration;
