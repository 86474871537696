const theme = {
  aspectRatios: {
    '1by1': '1 / 1',
    '4by3': '4 / 3',
    '3by4': '3 / 4',
    '5by7': '5 / 7',
    '7by5': '7 / 5',
    '9by16': '9 / 16',
    '16by9': '16 / 9',
    '2by1': '2 / 1',
  },
  container: {
    center: true,
    padding: {
      DEFAULT: '16px',
      xs: '26px',
      sm: '46px',
      md: '44px',
    },
  },
  colors: {
    transparent: 'transparent',
    blue: {
      lighter: '#E5F8FF',
      light: '#a9e8ff',
      DEFAULT: '#00b9ff',
      dark: '#0094cc',
    },
    white: '#ffffff',
    black: '#000000',
    gray: {
      darker: '#404042',
      dark: '#888888',
      DEFAULT: '#cccccc',
      light: '#e6e6e6',
      lighter: '#f8f8f8',
    },
    green: '#03DAA0',
    red: '#b45250',
  },
  spacing: {
    '0': '0',
    '1': '1px',
    '2': '2px',
    '4': '4px',
    '8': '8px',
    '12': '12px',
    '16': '16px',
    '24': '24px',
    '26': '26px',
    '32': '32px',
    '40': '40px',
    '48': '48px',
    '56': '56px',
    '64': '64px',
    '80': '80px',
    '100': '100px',
    '128': '128px',
    '256': '256px',
    '312': '312px',
    '428': '428px',
    '512': '512px',
    '1/2': '50%',
    '1/3': '33.333333%',
    '2/3': '66.666667%',
    '1/4': '25%',
    '2/4': '50%',
    '3/4': '75%',
    '1/5': '20%',
    '2/5': '40%',
    '3/5': '60%',
    '4/5': '80%',
    '1/6': '16.666667%',
    '2/6': '33.333333%',
    '3/6': '50%',
    '4/6': '66.666667%',
    '5/6': '83.333333%',
    '1/12': '8.333333%',
    '2/12': '16.666667%',
    '3/12': '25%',
    '4/12': '33.333333%',
    '5/12': '41.666667%',
    '6/12': '50%',
    '7/12': '58.333333%',
    '8/12': '66.666667%',
    '9/12': '75%',
    '10/12': '83.333333%',
    '11/12': '91.666667%',
  },
  borderWidth: {
    DEFAULT: '1px',
    '0': '0',
    '1': '1px',
    '2': '2px',
    '4': '4px',
    '8': '8px',
    '16': '16px',
  },
  borderColor: (theme) => ({ ...theme('colors') }),
  fontSize: {
    '5xl': `${65 / 16}rem`,
    '4xl': `${46 / 16}rem`,
    '3xl': `${32 / 16}rem`,
    '2xl': `${24 / 16}rem`,
    xl: `${22 / 16}rem`,
    lg: `${18 / 16}rem`,
    base: `${16 / 16}rem`,
    sm: `${12.8 / 16}rem`,
    xs: `${10.24 / 16}rem`,
    '2xs': `${8 / 16}rem`,
  },
  fontFamily: {
    display: ['Druk Text Wide Web', 'Arial Black', 'sans-serif'],
    condensed: ['Druk Web', 'Impact', 'sans-serif'],
    sans: ['Roboto', 'sans-serif'],
  },
  letterSpacing: {
    tightester: '-.06em',
    tightest: '-.04em',
    tighter: '-.03em',
    tight: '-.02em',
    normal: '0',
    wide: '.02em',
    wider: '.03em',
    widest: '.04em',
    widester: '.06em',
  },
  boxShadow: (theme) => ({
    '1': '0px 6px 30px 0px rgba(0,0,0,0.1)',
    '2': '0px 12px 50px 0px rgba(0,0,0,0.15)',
    '3': '0px 20px 70px 0px rgba(0,0,0,0.15)',
    '4': '0px 30px 90px 0px rgba(0,0,0,0.3)',
    '5': '0px 30px 90px 0px rgba(0,0,0,0.6)',
    bottom: 'inset 0px -1px 0px 0px rgba(136,136,136,1)',
    none: 'none',
    form: `inset 0 0 0 2px ${theme('colors.gray.lighter')}`,
    'form-dark': `inset 0 0 0 2px ${theme('colors.white')}`,
    'form-inset': `inset 0 0 0 1px ${theme('colors.gray.DEFAULT')}`,
    'form-inset-dark': `inset 0 0 0 1px ${theme('colors.gray.DEFAULT')}`,
    'form-focus': `inset 0 0 0 2px ${theme('colors.blue.DEFAULT')}, 0 2px 30px 0px  ${theme('colors.blue.DEFAULT')}22`,
  }),
  screens: {
    xs: '576px',
    sm: '768px',
    md: '992px',
    lg: '1280px',
  },
  extend: {
    lineHeight: {
      tightest: 1.1,
      tighter: 1.2,
      tight: 1.3,
      snug: 1.4,
    },
    transitionTimingFunction: {
      'ease-hover': 'cubic-bezier(0, 0.5, 1, 1)',
    },
    transitionDuration: {
      '150': '150ms',
    },
    cursor: {
      'zoom-in': 'zoom-in',
      'zoom-out': 'zoom-out',
    },
    width: {
      'screen-75': '75vw',
    },
    backgroundImage: (theme) => ({
      'panel-3/12-to-right': 'linear-gradient(to right, white 25%, #f8f8f8 0%)',
      'panel-4/12-to-right': 'linear-gradient(to right, white 33.33%, #f8f8f8 0%)',
      'panel-5/12-to-right': 'linear-gradient(to right, white 42.6%, #f8f8f8 0%)',
      'panel-6/12-to-left': 'linear-gradient(to left, white 50%, #f8f8f8 0%)',
      'panel-6/12-to-right': 'linear-gradient(to right, white 50%, #f8f8f8 0%)',
      'panel-7/12-to-right': 'linear-gradient(to right, white 58.33%, #f8f8f8 0%)',
      'panel-8/12-to-right': 'linear-gradient(to right, white 66.67%, #f8f8f8 0%)',
      'panel-8/12-to-left': 'linear-gradient(to left, white 66.67%, #f8f8f8 0%)',
      'panel-9/12-to-left': 'linear-gradient(to left, white 75%, #f8f8f8 0%)',
      'panel-9/12-to-right': 'linear-gradient(to right, white 75%, #f8f8f8 0%)',
      'panel-10/12-to-left': `linear-gradient(to left, white ${(10 / 12) * 100}%, #f8f8f8 0%)`,
      'panel-10/12-to-right': `linear-gradient(to right, white ${(10 / 12) * 100}%, #f8f8f8 0%)`,
      leader:
        "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAUAAAACCAYAAACQahZdAAAABGdBTUEAALGPC/xhBQAAABZJREFUCB1jePbs2X8QZkACTEhsOBMAFPMHZX2zwFAAAAAASUVORK5CYII=')",
    }),
    keyframes: {
      appear: {
        '0%': { opacity: 0 },
        '100%': { opacity: 1 },
      },
      pulse: {
        '0%': { opacity: 1 },
        '50%': { opacity: 0.3 },
        '100%': { opacity: 1 },
      },
      scaleIn: {
        from: { transform: 'scale(0.8)', opacity: 0 },
        to: { transform: 'scale(1)', opacity: 1 },
      },
      fadeIn: {
        from: { opacity: 0 },
        to: { opacity: 1 },
      },
    },
    animation: {
      scaleIn: 'scaleIn 0.15s ease-out',
      fadeIn: 'fadeIn 0.3s linear',
      appear: 'appear 0.3 linear',
      pulse: 'pulse 1s infinite',
    },
  },
};

module.exports = theme;
