import { useState, useRef, useEffect } from 'react';

type AnimateHeightProps = {
  open: boolean;
  duration?: number;
};

type Props = React.FC<React.HTMLAttributes<HTMLDivElement> & AnimateHeightProps>;

const AnimateHeight: Props = ({ className = '', open = false, duration = 200, ...props }) => {
  const childRef = useRef<HTMLDivElement>(null);
  const [heightVal, setHeightVal] = useState(open ? 'auto' : '0');
  const [hasBeenOpened, setHasBeenOpened] = useState(false);

  useEffect(() => {
    let timer1: ReturnType<typeof setTimeout>;
    let timer2: ReturnType<typeof setTimeout>;

    if (open) {
      setHasBeenOpened(true);
      setHeightVal(childRef.current?.offsetHeight + 'px');
      timer1 = setTimeout(() => {
        setHeightVal('auto');
      }, duration);
    } else if (!open && hasBeenOpened) {
      setHeightVal(childRef.current?.offsetHeight + 'px');
      timer2 = setTimeout(() => {
        setHeightVal('0px');
      }, 50);
    }

    return () => {
      clearTimeout(timer1);
      clearTimeout(timer2);
    };
  }, [open]);

  return (
    <div
      {...props}
      className={`overflow-hidden ${className}`}
      style={{ height: heightVal, transition: `all ${duration}ms ease-out` }}
    >
      <div ref={childRef}>{props.children}</div>
    </div>
  );
};

export default AnimateHeight;
