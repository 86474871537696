import { Link, SanityKeyed } from '../../../../sanity/schema-types';
import LinkComponent from 'next/link';

type Props = {
  links: SanityKeyed<Link>[];
};

const UrlColumn = ({ links }: Props) => {
  return (
    <nav>
      <ul className="ml-0 list-none space-y-8">
        {links.map((link) => (
          <li key={link._key}>
            <LinkComponent href={link.href}>
              <a className="text-sm text-gray-dark">{link.title}</a>
            </LinkComponent>
          </li>
        ))}
      </ul>
    </nav>
  );
};

export default UrlColumn;
