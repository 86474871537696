import React from 'react';
import SalesCallout from 'components/SalesCallout';

type SalesCalloutHTOProps = {
  className?: string;
  ctaPageName: string;
};

const SalesCalloutHTO = ({ className, ctaPageName }: SalesCalloutHTOProps) => {
  return (
    <SalesCallout
      calloutId="sales_callout_home_try_on"
      className={className ? className : ''}
      ctaPageName={ctaPageName}
      ctaTitle="Start Try-on"
      href="/home-try-on"
      title="Free Home Try-on"
      image={
        <div className="flex max-w-full" style={{ aspectRatio: '276 / 196' }}>
          <picture>
            <img
              loading="lazy"
              src="https://gentux.imgix.net/1666925206_1663789973MGSiteOfferCalloutsHTO.jpeg?w=276&dpr=2"
              alt="Menguin box in front of a door"
            />
          </picture>
        </div>
      }
    >
      You won't find this in stores. Ensure the right fit with a free home try-on.
    </SalesCallout>
  );
};

export default SalesCalloutHTO;
