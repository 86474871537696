import Link from 'next/link';

import { Navigation } from '@root/sanity/schema-types';
import useVisible from 'hooks/useVisible';
import useLockBodyScroll from 'hooks/useLockBodyScroll';
import IconX from 'components/IconX';
import Logo from 'components/Logo';
import NavItem, { navItemClassNames } from 'partials/nav/NavItem';

type NavMainProps = {
  pathname: string;
  navigation: Navigation;
};

const NavMain = ({ pathname, navigation }: NavMainProps) => {
  const { ref, isVisible, setIsVisible } = useVisible(false);

  useLockBodyScroll(isVisible);

  const renderNavItems = (isMobile: boolean) =>
    navigation.navigationBuilder
      ?.filter((item) => !item.hidden)
      .map((item) => (
        <NavItem
          isMobile={isMobile}
          item={item}
          key={`${item.title}-${item.href}`}
          pathname={pathname}
          setIsVisible={setIsVisible}
        />
      ));

  return (
    <header>
      <nav>
        <div className="flex h-[60] items-stretch justify-between bg-gray-lighter px-16 md:px-32">
          <Link href="/" passHref>
            <a className="flex items-center py-4 text-sm sm:py-16">
              <Logo />
            </a>
          </Link>

          <div ref={ref} className="relative flex flex-grow items-stretch justify-start md:pl-16">
            <div
              className={`flex cursor-pointer items-center ${
                isVisible ? 'py-16' : 'py-12'
              } px-16 text-xl hover:bg-gray-light md:hidden`}
              onClick={() => setIsVisible(!isVisible)}
            >
              {isVisible ? (
                <IconX className="h-24 w-24" style={{ color: '#878787', height: 24, width: 24 }} />
              ) : (
                <div className="-py-2">&#9776;</div>
              )}
            </div>

            {/*Mobile NavBar*/}
            <div
              className={`${isVisible ? '' : 'hidden'} 
               fixed left-0 bottom-0 z-30 w-2/3 overflow-auto bg-white px-24 py-8 shadow-5 md:relative md:top-auto md:hidden md:flex-grow md:flex-row md:items-center md:space-y-0 md:space-x-32 md:p-0 md:shadow-none`}
              style={{ top: '109px' }}
            >
              {renderNavItems(true)}
            </div>

            {/*Desktop NavBar*/}
            <div className="absolute bottom-0 m-0 hidden translate-y-[100%] list-none items-stretch bg-white p-32 shadow-3 md:relative md:flex md:translate-y-0 md:bg-transparent md:p-0 md:shadow-none">
              {renderNavItems(false)}
            </div>

            <div className="hidden items-stretch xs:flex md:hidden">
              <Link as="/collection/tuxedos-and-suits" href="/collection/[category]" passHref>
                <a className={`${navItemClassNames} ${pathname.includes('/collection/') && 'text-blue-dark'}`}>
                  Collection
                </a>
              </Link>
            </div>
          </div>
          <div className="flex">
            <ul className="flex list-none items-stretch space-x-16">
              <li className="flex items-stretch">
                <a href={`${process.env.NEXT_PUBLIC_APP_URL}/account/login`} className="flex items-center text-black">
                  Login
                </a>
              </li>
              <li className="flex items-stretch">
                <a href={`${process.env.NEXT_PUBLIC_APP_URL}/signup/email`} className="flex items-center text-black">
                  Sign Up
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </header>
  );
};

export default NavMain;
