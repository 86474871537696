import React from 'react';

interface IconFacebookProps {}

type Props = React.FC<React.HTMLAttributes<HTMLOrSVGElement> & IconFacebookProps>;

const IconFacebook: Props = ({ className, ...props }) => {
  return (
    <svg
      viewBox="0 0 16 16"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      className={`h-16 w-16 fill-current text-black ${className ? className : ''}`}
    >
      <title>Facebook logo</title>
      <path d="M16 8c0-4.4-3.6-8-8-8S0 3.6 0 8c0 4 2.9 7.3 6.8 7.9v-5.6h-2V8h2V6.2c0-2 1.2-3.1 3-3.1.9 0 1.8.2 1.8.2v2h-1c-1 0-1.3.6-1.3 1.2V8h2.2l-.4 2.3H9.2v5.6C13.1 15.3 16 12 16 8z" />
    </svg>
  );
};

export default IconFacebook;
