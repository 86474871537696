const safelist = [
  '-mt-32',
  '-mt-64',
  '-mt-128',
  '-mb-32',
  '-mb-64',
  '-mb-128',
  'mt-128',
  'mb-128',
  'pb-32',
  'pb-64',
  'pb-128',
  'lg:pb-128',
  'pt-32',
  'pt-64',
  'pt-128',
];

module.exports = safelist;
