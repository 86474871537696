import React from 'react';

interface LineProps {}

type Props = React.FC<React.HTMLAttributes<HTMLHRElement> & LineProps>;

const Line: Props = ({ className = '', ...props }) => {
  return <hr {...props} className={`my-16 w-32 text-gray sm:!my-32 ${className}`} />;
};

export default Line;
