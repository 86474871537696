import React from 'react';
import SalesCallout from 'components/SalesCallout';

type SalesCalloutGroomsFreeProps = {
  className?: string;
  ctaPageName: string;
};

const SalesCalloutGroomsFree = ({ className, ctaPageName }: SalesCalloutGroomsFreeProps) => {
  return (
    <SalesCallout
      calloutId="sales_callout_grooms_free"
      className={className ? className : ''}
      ctaPageName={ctaPageName}
      ctaTitle="See Offer"
      href="/how-it-works/free-groom-rental"
      title="Free Rental for Wedding Couples"
      image={
        <div className="flex max-w-full" style={{ aspectRatio: '277 / 197' }}>
          <picture>
            <img
              loading="lazy"
              src="https://gentux.imgix.net/1685395149_MGSiteOffersFreeRental.jpg?w=277&dpr=2"
              alt="A bride embracing a groom in front of some stairs."
            />
          </picture>
        </div>
      }
    >
      With 5 members or more in your wedding party, the couple qualifies for a free suit or tuxedo rental.
    </SalesCallout>
  );
};

export default SalesCalloutGroomsFree;
