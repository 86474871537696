import { ReactNode } from 'react';

import type { Picture as SanityPictureType } from '@root/sanity/schema-types';
import { urlFor } from '@root/lib/sanity';
import SanityPicture from 'components/SanityPicture';

export type NumberedListItem = {
  content: ReactNode;
  headerText: string;
  image: SanityPictureType;
};

type NumberedListProps = {
  className?: string;
  items: NumberedListItem[];
};

const NumberedList = ({ className = '', items }: NumberedListProps) => {
  return (
    <div className={`container bg-white sm:bg-transparent ${className}`}>
      <div className="sm:bg-panel-8/12-to-left">
        {items.map((item, index) => (
          <div className="row space-y-32 pr-64 sm:py-64" key={item.headerText}>
            <div className="hidden lg:col-span-1 lg:block" />

            <p className="col-span-2 sm:col-span-1 md:col-span-2">0{index + 1}</p>

            <SanityPicture
              alt={item.image.alt || ''}
              bg="fff"
              className="col-span-10 xs:col-span-8 sm:col-span-5 md:col-span-4"
              polaroid={item.image.polaroid}
              url={urlFor(item.image.asset).url()}
            />

            <div className="col-span-2 sm:hidden md:col-span-1 md:block" />

            <div className="hidden xs:col-span-2 sm:hidden" />

            <div className="col-span-10 space-y-16 xs:col-span-8 sm:col-span-6 md:col-span-5 lg:col-span-4">
              <h4 className="text-h4-display">{item.headerText}</h4>

              <div>{item.content}</div>
            </div>

            <div className="col-span-2 sm:hidden md:col-span-1 md:block" />
          </div>
        ))}
      </div>
    </div>
  );
};

export default NumberedList;
