const pageSchema = [
  {
    pathname: '/collection/tuxedos-and-suits/black-notch-lapel-tuxedo',
    schema: `<script type="application/ld+json">
{
  "@context": "https://schema.org/", 
  "@type": "Product", 
  "name": "Black Notch Lapel Tuxedo",
  "image": "https://gentux.imgix.net/Black%20Notch%20-%20171031_Menguin_11760-1.jpg?fm=jpg&fit=crop&w=775&ar=5:7&auto=format",
  "description": "A black notch lapel tuxedo created to impress any audience. Timeless style in a contemporary cut, featuring Super 140’s wool, flat front pants and sizing available in both modern and slim fits. The Devil is in the details. Click below to see ours. Our tuxedo pants do not have belt loops and cannot be worn with a belt; however, they do accept clip-in suspenders.",
  "offers": {
    "@type": "Offer",
    "url": "https://www.menguin.com/collection/tuxedos-and-suits/black-notch-lapel-tuxedo",
    "priceCurrency": "USD",
    "price": "149"
  },
  "aggregateRating": {
    "@type": "AggregateRating",
    "ratingValue": "5",
    "bestRating": "5",
    "ratingCount": "20"
  }
}
</script>`,
  },
  {
    pathname: '/collection/tuxedos-and-suits/black-peak-lapel-tuxedo',
    schema: `<script type="application/ld+json">
{
  "@context": "https://schema.org/", 
  "@type": "Product", 
  "name": "Black Peak Lapel Tuxedo",
  "image": "https://gentux.imgix.net/Peak%20Black%20Tux%20-%20171031_Menguin_12428-1.jpg?fm=jpg&fit=crop&w=1200&ar=5:7&auto=format",
  "description": "A black, peak lapel tuxedo designed to cut through the crowd. Stay sharp. Super 140’s Merino wool, flat front pants and sizing available in both modern and slim fits. Free shipping with every Tux/Suit rental. Our tuxedo pants do not have belt loops and cannot be worn with a belt; however, they do accept clip-in suspenders.",
  "offers": {
    "@type": "Offer",
    "url": "https://www.menguin.com/collection/tuxedos-and-suits/black-peak-lapel-tuxedo",
    "priceCurrency": "USD",
    "price": "149"
  },
  "aggregateRating": {
    "@type": "AggregateRating",
    "ratingValue": "5",
    "bestRating": "5",
    "ratingCount": "6"
  }
}
</script>`,
  },
  {
    pathname: '/collection/tuxedos-and-suits/black-shawl-lapel-tuxedo',
    schema: `<script type="application/ld+json">
{
  "@context": "https://schema.org/", 
  "@type": "Product", 
  "name": "Black Shawl Lapel Tuxedo",
  "image": "https://gentux.imgix.net/Shawl%20Tux%20-%20171031_Menguin_11062-1.jpg?fm=jpg&fit=crop&w=775&ar=5:7&auto=format",
  "description": "A black shawl lapel tuxedo that puts the dap in dapper. This classic look has been modernized for a new, clean look. This single breasted tux features our Super 140’s Merino wool and flat front pants. Shawl tuxedo rental available in both modern and slim fits. Free shipping with every Tux/Suit rental. Our tuxedo pants do not have belt loops and cannot be worn with a belt; however, they do accept clip-in suspenders.",
  "offers": {
    "@type": "Offer",
    "url": "https://www.menguin.com/collection/tuxedos-and-suits/black-shawl-lapel-tuxedo",
    "priceCurrency": "USD",
    "price": "149"
  },
  "aggregateRating": {
    "@type": "AggregateRating",
    "ratingValue": "5",
    "bestRating": "5",
    "ratingCount": "8"
  }
}
</script>`,
  },
  {
    pathname: '/collection/tuxedos-and-suits/blue-chambray-suit',
    schema: `<script type="application/ld+json">
{
  "@context": "https://schema.org/", 
  "@type": "Product", 
  "name": "Blue Chambray Suit",
  "image": "https://gentux.imgix.net/Tropical%20Blue%20Notch%20Suit%20-%20171031_Menguin_10987-1.jpg?fm=jpg&fit=crop&w=775&ar=5:7&auto=format",
  "description": "A Blue Chambray notch lapel suit that is lightweight and pops. No need to dress with bold colors, just add some neutrality to this modern design. Men's blue chambray suit is available in modern and slim fit. Free Shipping with every Tux/Suit Rental",
  "offers": {
    "@type": "Offer",
    "url": "https://www.menguin.com/collection/tuxedos-and-suits/blue-chambray-suit",
    "priceCurrency": "USD",
    "price": "149"
  },
  "aggregateRating": {
    "@type": "AggregateRating",
    "ratingValue": "5",
    "bestRating": "5",
    "ratingCount": "7"
  }
}
</script>`,
  },
  {
    pathname: '/collection/tuxedos-and-suits/blue-edge-lapel-tuxedo',
    schema: `<script type="application/ld+json">
{
  "@context": "https://schema.org/", 
  "@type": "Product", 
  "name": "Blue Edge Notch Lapel Tuxedo",
  "image": "https://gentux.imgix.net/Mystic%20Blue%20Tux%20-%20171031_Menguin_12283-1.jpg?fm=jpg&fit=crop&w=775&ar=5:7&auto=format",
  "description": "A break from the traditional tuxedo. A bold blue, brighter than navy, notch lapel assures that you are the focus of the room. The tuxedo features Super 130’s Merino wool, flat front pants and sizing available in both modern and slim fits. This blue will look great with black or brown shoes, just make sure not to forget the matching vest. For Rent Only. Our tuxedo pants do not have belt loops and cannot be worn with a belt; however, they do accept clip-in suspenders.",
  "offers": {
    "@type": "Offer",
    "url": "https://www.menguin.com/collection/tuxedos-and-suits/blue-edge-lapel-tuxedo",
    "priceCurrency": "USD",
    "price": "149"
  },
  "aggregateRating": {
    "@type": "AggregateRating",
    "ratingValue": "5",
    "bestRating": "5",
   
    "ratingCount": "4"
  }
}
</script>`,
  },
  {
    pathname: '/collection/tuxedos-and-suits/charcoal-notch-lapel-tuxedo',
    schema: `<script type="application/ld+json">
{
  "@context": "https://schema.org/", 
  "@type": "Product", 
  "name": "Charcoal Notch Lapel Tuxedo",
  "image": "https://gentux.imgix.net/Dark%20Grey%20Tux%20-%20171031_Menguin_11030-1.jpg?fm=jpg&fit=crop&w=775&ar=5:7&auto=format",
  "description": "A charcoal notch lapel tuxedo that will change the way you think about black tie. This style is perfect for any time of year and can be worn with black gloss or matte shoes. Super 130’s wool, flat front pants and sizing available in both modern and slim fits. The Devil is in the details. Click below to see ours. Our tuxedo pants do not have belt loops and cannot be worn with a belt; however, they do accept clip-in suspenders.",
  "offers": {
    "@type": "Offer",
    "url": "https://www.menguin.com/collection/tuxedos-and-suits/charcoal-notch-lapel-tuxedo",
    "priceCurrency": "USD",
    "price": "149"
  },
  "aggregateRating": {
    "@type": "AggregateRating",
    "ratingValue": "5",
    "bestRating": "5",
    "ratingCount": "6"
  }
}
</script>`,
  },
  {
    pathname: '/collection/tuxedos-and-suits/charcoal-suit',
    schema: `<script type="application/ld+json">
{
  "@context": "https://schema.org/", 
  "@type": "Product", 
  "name": "Charcoal Suit",
  "image": "https://gentux.imgix.net/Charcoal%20Suit%20-%20171031_Menguin_11383-1.jpg?fm=jpg&fit=crop&w=775&ar=5:7&auto=format",
  "description": "A charcoal gray notch lapel suit that is perfect for any event, day or night. This charcoal gray suit rental features Super 130’s Merino wool, flat front pants and sizing available in both modern and slim fits. Free shipping with every Tux/Suit rental.",
  "offers": {
    "@type": "Offer",
    "url": "https://www.menguin.com/collection/tuxedos-and-suits/charcoal-suit",
    "priceCurrency": "USD",
    "price": "149"
  },
  "aggregateRating": {
    "@type": "AggregateRating",
    "ratingValue": "5",
    "bestRating": "5",
    "ratingCount": "11"
  }
}
</script>`,
  },
  {
    pathname: '/collection/tuxedos-and-suits/classic-black-suit',
    schema: `<script type="application/ld+json">
{
  "@context": "https://schema.org/", 
  "@type": "Product", 
  "name": "Classic Black Suit",
  "image": "https://gentux.imgix.net/Black%20Suit%20-%20171031_Menguin_12013-1.jpg?fm=jpg&fit=crop&w=775&ar=5:7&auto=format",
  "description": "A black, two-button notch lapel suit. Perfect look for any evening affair. Go classic black tie or add a splash of color to stand out. The suit features super 130’s Merino wool, flat front pants and sizing available in both modern and slim fit. Free shipping with every Tux/Suit rental.",
  "offers": {
    "@type": "Offer",
    "url": "https://www.menguin.com/collection/tuxedos-and-suits/classic-black-suit",
    "priceCurrency": "USD",
    "price": "149"
  },
  "aggregateRating": {
    "@type": "AggregateRating",
    "ratingValue": "5",
    "bestRating": "5",   
    "ratingCount": "17"
  }
}
</script>`,
  },
  {
    pathname: '/collection/tuxedos-and-suits/cobalt-blue-suit',
    schema: `<script type="application/ld+json">
{
  "@context": "https://schema.org/", 
  "@type": "Product", 
  "name": "Cobalt Blue Suit",
  "image": "https://gentux.imgix.net/Bright%20Blue%20-%20171031_Menguin_11124-1.jpg?fm=jpg&fit=crop&w=775&ar=5:7&auto=format",
  "description": "A cobalt blue notch lapel suit that allows you to be flexible in your style choice. From accessory colors to shoes, you have the freedom to make this modern blue suit look all yours. Super 130’s Merino wool, flat front pants and sizing available in both modern and slim fits. Free Shipping with every Tux/Suit Rental",
  "offers": {
    "@type": "Offer",
    "url": "https://www.menguin.com/collection/tuxedos-and-suits/cobalt-blue-suit",
    "priceCurrency": "USD",
    "price": "149"
  },
  "aggregateRating": {
    "@type": "AggregateRating",
    "ratingValue": "6",
    "bestRating": "5",   
    "ratingCount": "20"
  }
}
</script>`,
  },
  {
    pathname: '/collection/tuxedos-and-suits/deep-blue-suit',
    schema: `<script type="application/ld+json">
{
  "@context": "https://schema.org/", 
  "@type": "Product", 
  "name": "Deep Blue Suit",
  "image": "https://gentux.imgix.net/Mystic%20Blue%20-%20171031_Menguin_11926-1.jpg?fm=jpg&fit=crop&w=775&ar=5:7&auto=format",
  "description": "A deep blue peak lapel suit that takes traditional blue to a whole new level. This fitted suit rental features super 130’s Merino wool, flat front pants and sizing available in both modern and slim fits. Free shipping with every Tux/Suit rental.",
  "offers": {
    "@type": "Offer",
    "url": "https://www.menguin.com/collection/tuxedos-and-suits/deep-blue-suit",
    "priceCurrency": "USD",
    "price": "149"
  },
  "aggregateRating": {
    "@type": "AggregateRating",
    "ratingValue": "5",
    "bestRating": "5",   
    "ratingCount": "42"
  }
}
</script>`,
  },
  {
    pathname: '/collection/tuxedos-and-suits/gray-plaid-suit',
    schema: `<script type="application/ld+json">
{
  "@context": "https://schema.org/", 
  "@type": "Product", 
  "name": "Gray Plaid Suit",
  "image": "https://gentux.imgix.net/Grey%20Plaid%20-%20171031_Menguin_12409-1.jpg?fm=jpg&fit=crop&w=775&ar=5:7&auto=format",
  "description": "A gray plaid notch lapel suit that disrupts the status quo and kicks stylish up a notch. This gray plaid suit features an ombre pattern, Super 130’s Merino wool, flat front pants, and sizing available in both modern and slim fits. Pair these men’s wool suits with brown or black shoes, bold or subdued accessories. Free Shipping with every Tux/Suit Rental",
  "offers": {
    "@type": "Offer",
    "url": "https://www.menguin.com/collection/tuxedos-and-suits/gray-plaid-suit",
    "priceCurrency": "USD",
    "price": "149"
  },
  "aggregateRating": {
    "@type": "AggregateRating",
    "ratingValue": "5",
    "bestRating": "5",
    "ratingCount": "3"
  }
}
</script>`,
  },
  {
    pathname: '/collection/tuxedos-and-suits/gray-suit',
    schema: `<script type="application/ld+json">
{
  "@context": "https://schema.org/", 
  "@type": "Product", 
  "name": "Gray Suit",
  "image": "https://gentux.imgix.net/Grey%20Suit%20-%20171031_Menguin_11650-1.jpg?fm=jpg&fit=crop&w=775&ar=5:7&auto=format",
  "description": "A gray notch lapel suit, this styling allows you to dress up or down depending on the need. Bold or light accessory colors will go beautifully. This gray 3-piece suit features Super 130’s Merino wool, flat front pants and sizing available in both modern and slim fits. Feel free to wear this medium gray suit with black or brown shoes, just make sure that the accessories match. Free shipping with every Tux/Suit rental.",
  "offers": {
    "@type": "Offer",
    "url": "https://www.menguin.com/collection/tuxedos-and-suits/gray-suit",
    "priceCurrency": "USD",
    "price": "149"
  },
  "aggregateRating": {
    "@type": "AggregateRating",
    "ratingValue": "5",
    "bestRating": "5",
    "ratingCount": "39"
  }
}
</script>`,
  },
  {
    pathname: '/collection/tuxedos-and-suits/light-blue-suit',
    schema: `<script type="application/ld+json">
{
  "@context": "https://schema.org/", 
  "@type": "Product", 
  "name": "Light Blue Suit",
  "image": "https://gentux.imgix.net/1616786952_MGPDPAlt1LightBlueSuit2021032410000.jpg?fm=jpg&fit=crop&w=775&ar=5:7&auto=format",
  "description": "A light blue notch lapel suit that is the perfect look for every occasion. Try pastel accessories to compliment your look. This men’s light blue suit rental features a blue and white woven fabric that adds depth to this look. Free shipping with every Tux/Suit rental.",
  "offers": {
    "@type": "Offer",
    "url": "https://www.menguin.com/collection/tuxedos-and-suits/light-blue-suit",
    "priceCurrency": "USD",
    "price": "149"
  }
}
</script>`,
  },
  {
    pathname: '/collection/tuxedos-and-suits/light-gray-suit',
    schema: `<script type="application/ld+json">
{
  "@context": "https://schema.org/", 
  "@type": "Product", 
  "name": "Light Gray Suit",
  "image": "https://gentux.imgix.net/Cement%20Gray%20-%20171031_Menguin_11463-1.jpg?fm=jpg&fit=crop&w=775&ar=5:7&auto=format",
  "description": "A light gray notch lapel suit that changes the standard for elegance. Wear a look that stands out and allows you flexibility in accessory colors. Super 130’s wool, flat front pants and sizing available in both modern and slim fits. For Rent Only.",
  "offers": {
    "@type": "Offer",
    "url": "https://www.menguin.com/collection/tuxedos-and-suits/light-gray-suit",
    "priceCurrency": "USD",
    "price": "149"
  },
  "aggregateRating": {
    "@type": "AggregateRating",
    "ratingValue": "5",
    "bestRating": "5",
    "ratingCount": "5"
  }
}
</script>`,
  },
  {
    pathname: '/collection/tuxedos-and-suits/light-gray-tuxedo',
    schema: `<script type="application/ld+json">
{
  "@context": "https://schema.org/", 
  "@type": "Product", 
  "name": "Light Gray Tuxedo",
  "image": "https://gentux.imgix.net/Grey%20Tux%20-%20171031_Menguin_11315-1.jpg?fm=jpg&fit=crop&w=775&ar=5:7&auto=format",
  "description": "A light gray notch lapel tuxedo that is as versatile as it is stylish. Black or brown shoes, bold or pastel colors; either way you will be the best dressed in the room. This light gray tux features Super 130’s wool, flat front pants and sizing available in both modern and slim fits. For Rent Only. Our tuxedo pants do not have belt loops and cannot be worn with a belt; however, they do accept clip-in suspenders.",
  "offers": {
    "@type": "Offer",
    "url": "https://www.menguin.com/collection/tuxedos-and-suits/light-gray-tuxedo",
    "priceCurrency": "USD",
    "price": "149"
  },
  "aggregateRating": {
    "@type": "AggregateRating",
    "ratingValue": "5",
    "bestRating": "5",
    "ratingCount": "6"
  }
}
</script>`,
  },
  {
    pathname: '/collection/tuxedos-and-suits/navy-peak-lapel-tuxedo',
    schema: `<script type="application/ld+json">
{
  "@context": "https://schema.org/", 
  "@type": "Product", 
  "name": "Navy Peak Lapel Tuxedo",
  "image": "https://gentux.imgix.net/Midnight%20Blue%20-%20171031_Menguin_10910-1.jpg?fm=jpg&fit=crop&w=775&ar=5:7&auto=format",
  "description": "Whether it be the Grammy's, Golden Globes or Academy Awards, our navy peak lapel tuxedo will accommodate. The navy tuxedo features Super 140's Merino wool, black satin-faced peak lapels, and vents accommodating both modern and slim fits. Perfection personified. Free shipping with every Tux/Suit rental. Our tuxedo pants do not have belt loops and cannot be worn with a belt; however, they do accept clip-in suspenders.",
  "offers": {
    "@type": "Offer",
    "url": "https://www.menguin.com/collection/tuxedos-and-suits/navy-peak-lapel-tuxedo",
    "priceCurrency": "USD",
    "price": "149"
  },
  "aggregateRating": {
    "@type": "AggregateRating",
    "ratingValue": "5",
    "bestRating": "5",
    "ratingCount": "6"
  }
}
</script>`,
  },
  {
    pathname: '/collection/tuxedos-and-suits/navy-suit',
    schema: `<script type="application/ld+json">
{
  "@context": "https://schema.org/", 
  "@type": "Product", 
  "name": "Navy Suit",
  "image": "https://gentux.imgix.net/Navy%20Suit%20-%20171031_Menguin_11610-1.jpg?fm=jpg&fit=crop&w=775&ar=5:7&auto=format",
  "description": "A navy blue notch lapel suit that features Super 130’s Merino wool, flat front pants and sizing available in both modern and slim fits. This slim fit suit can be paired with black or brown shoes depending on the accessory color. Free shipping with every Tux/Suit rental.",
  "offers": {
    "@type": "Offer",
    "url": "https://www.menguin.com/collection/tuxedos-and-suits/navy-suit",
    "priceCurrency": "USD",
    "price": "149"
  },
  "aggregateRating": {
    "@type": "AggregateRating",
    "ratingValue": "5",
    "bestRating": "5",
    "ratingCount": "23"
  }
}
</script>`,
  },
  {
    pathname: '/collection/tuxedos-and-suits/steel-gray-suit',
    schema: `<script type="application/ld+json">
{
  "@context": "https://schema.org/", 
  "@type": "Product", 
  "name": "Steel Gray Suit",
  "image": "https://gentux.imgix.net/Dark%20Grey%20Suit%20-%20171031_Menguin_11359-1.jpg?fm=jpg&fit=crop&w=775&ar=5:7&auto=format",
  "description": "An ode to gray. Single button, peak lapel and the ability to dress up or down depending on the occasion. As always, this men’s suit rental features Super 130’s Merino wool, flat front pants and sizing available in both modern and slim fits. You will look amazing in this steel gray slim fit suit that can be paired with any choice of shoe, black or brown. Free shipping with every Tux/Suit rental.",
  "offers": {
    "@type": "Offer",
    "url": "https://www.menguin.com/collection/tuxedos-and-suits/steel-gray-suit",
    "priceCurrency": "USD",
    "price": "149"
  },
  "aggregateRating": {
    "@type": "AggregateRating",
    "ratingValue": "5",
    "bestRating": "5",
    "ratingCount": "29"
  }
}
</script>`,
  },
  {
    pathname: '/collection/tuxedos-and-suits/tan-suit',
    schema: `<script type="application/ld+json">
{
  "@context": "https://schema.org/", 
  "@type": "Product", 
  "name": "Tan Suit",
  "image": "https://gentux.imgix.net/1616702188_MGPDPAlt1TanSuit2021032410000.jpg?fm=jpg&fit=crop&w=775&ar=5:7&auto=format",
  "description": "A tan notch lapel suit that is the perfect look for that warm weather climate engagement. Try pastel accessories to lighten your look. The men’s tan suit rental features Super 130’s wool, flat front pants and sizing available in slim, modern and boys fit. Free shipping with every Tux/Suit Rental.",
  "offers": {
    "@type": "Offer",
    "url": "https://www.menguin.com/collection/tuxedos-and-suits/tan-suit",
    "priceCurrency": "USD",
    "price": "149"
  },
  "aggregateRating": {
    "@type": "AggregateRating",
    "ratingValue": "5",
    "bestRating": "5",
    "ratingCount": "2"
  }
}
</script>`,
  },
  {
    pathname: '/collection/tuxedos-and-suits/white-dinner-jacket',
    schema: `<script type="application/ld+json">
{
  "@context": "https://schema.org/", 
  "@type": "Product", 
  "name": "White Dinner Jacket",
  "image": "https://gentux.imgix.net/White%20Jacket%20-%20171031_Menguin_12037-1.jpg?fm=jpg&fit=crop&w=775&ar=5:7&auto=format",
  "description": "Mix southern charm with a splash of James Bond and you will find our white shawl lapel tuxedo is the result. The jacket is a poly/wool blend with satin trim and is available in modern fit only. The white and black tuxedo rental comes with black flat front pants to break up the look and add a touch of elegance. Free shipping with every Tux/Suit rental. Our tuxedo pants do not have belt loops and cannot be worn with a belt; however, they do accept clip-in suspenders.",
  "offers": {
    "@type": "Offer",
    "url": "https://www.menguin.com/collection/tuxedos-and-suits/white-dinner-jacket",
    "priceCurrency": "USD",
    "price": "99"
  },
  "aggregateRating": {
    "@type": "AggregateRating",
    "ratingValue": "5",
    "bestRating": "5",
    "ratingCount": "1"
  }
}
</script>`,
  },
];

const PageSchemaFn = (pathname: string) => pageSchema.find((p) => p.pathname === pathname);

export default PageSchemaFn;
