import React from 'react';

interface IconTwitterProps {}

type Props = React.FC<React.HTMLAttributes<HTMLOrSVGElement> & IconTwitterProps>;

const IconTwitter: Props = ({ className, ...props }) => {
  return (
    <svg
      viewBox="0 0 16 16"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      className={`h-16 w-16 fill-current text-black ${className ? className : ''}`}
    >
      <title>Twitter logo</title>
      <path d="M5.127 14.906c6.011 0 9.282-4.95 9.282-9.282v-.442c.619-.442 1.149-1.06 1.591-1.68a7.339 7.339 0 01-1.856.531c.707-.442 1.149-1.06 1.414-1.768a8.11 8.11 0 01-2.033.796C12.906 2.442 12.11 2 11.138 2c-1.768 0-3.27 1.503-3.27 3.27 0 .266 0 .531.088.708C5.216 5.8 2.829 4.564 1.238 2.53.972 2.972.796 3.591.796 4.21c0 1.15.618 2.121 1.414 2.74-.53 0-1.06-.177-1.503-.442A3.23 3.23 0 003.36 9.691c-.265.088-.53.088-.884.088-.177 0-.442 0-.619-.088.442 1.326 1.592 2.21 3.006 2.298-1.15.884-2.564 1.414-4.066 1.414-.266 0-.53 0-.796-.088 1.503 1.06 3.27 1.591 5.127 1.591z" />
    </svg>
  );
};

export default IconTwitter;
