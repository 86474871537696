import React from 'react';

type IconListRowProps = {
  src: string;
  alt: string;
  number: string;
  headline: string;
  body: string;
  className?: string;
};

const IconListRow = ({ src, alt, number, headline, body, className }: IconListRowProps) => (
  <div className={`row items-center ${className ?? ''}`}>
    <div className="col-span-4 flex justify-center sm:col-span-3">
      <div
        className="
        relative inline-block max-w-[90px] rounded-full bg-white 
        before:absolute before:left-[-4px] before:top-[50%] before:h-1 before:w-[800px] before:translate-x-[-100%] before:bg-gray-light
        after:absolute after:left-[-4px] after:right-[-4px] after:top-[-4px] after:bottom-[-4px] after:block after:rounded-full after:border after:border-gray-light
        "
      >
        <img className="block aspect-ratio-1by1" src={src} alt={alt} loading="lazy" />
      </div>
    </div>

    <div className="col-start-5 col-end-13 sm:col-start-5">
      <div className="row">
        <div className="col-span-7 sm:col-span-1">
          <p>{number}</p>
        </div>
        <div className="col-span-12 sm:col-span-4 lg:col-span-4">
          <h4 className="text-h4-display mb-8 sm:mb-16">{headline}</h4>
        </div>
        <div className="col-span-12 sm:col-span-6 lg:col-span-6">
          <p>{body}</p>
        </div>
      </div>
    </div>
  </div>
);

export default IconListRow;
