import React, { PropsWithChildren } from 'react';
import Link from 'next/link';
import NavMain from 'partials/nav/NavMain';
import NavSub from 'partials/nav/NavSub';
import FooterComponent from 'partials/nav/Footer';
import HeaderComponent from 'partials/nav/Header';
import { SeoData } from 'types';
import IconArrowRight from 'components/IconArrowRight';
import { Disclosure } from '@headlessui/react';
import AnimateHeight from 'components/AnimateHeight';
import IconAlert from 'components/IconAlert';
import IconTriangleDown from 'components/IconTriangleDown';
import PageSchema from 'data/PageSchema';

type Props = {
  seo: SeoData;
  pathname: string;
  canonicalUrl: string | null;
  pageProps: any;
};

const Main = ({ children, seo, pathname, canonicalUrl, pageProps }: PropsWithChildren<Props>) => {
  const { navigation, callout, footer } = pageProps;

  const pageSchema = PageSchema(pathname);
  const renderNav = !pathname.includes('/app/') && !pathname.includes('/checkout') && navigation;

  return (
    <div className="bg-gray-lighter">
      <HeaderComponent seo={seo} pathname={pathname} canonicalUrl={canonicalUrl} />
      {pageSchema && (
        <div
          dangerouslySetInnerHTML={{
            __html: pageSchema.schema,
          }}
        />
      )}
      <div id="fb-root" />
      <div className="body-main-container">
        {!pathname.includes('/app/') && callout && (
          <div className={`bg-blue-light pl-16 pr-8 xs:pr-16 md:pl-32 ${!callout.show && 'hidden'}`}>
            <Disclosure as="div">
              {({ open }) => (
                <>
                  <Disclosure.Button className="group flex w-full items-center justify-start py-16">
                    <IconAlert className={`mr-8 !text-blue-dark ${!callout.showAlertIcon && 'hidden'}`} />

                    {callout.headline && <p className="text-h5 text-blue-dark">{callout.headline}</p>}

                    <IconTriangleDown
                      className={`group-hover:text-blue-darker ml-auto mr-2 fill-current  transition duration-150 ${
                        open ? '' : 'rotate-90 transform'
                      }`}
                    />
                  </Disclosure.Button>

                  <AnimateHeight open={open}>
                    <Disclosure.Panel static className="space-y-16 pb-16">
                      <div className="space-y-16 pb-16">
                        <p className="max-w-screen-xs text-gray-darker">{callout.description}</p>

                        {callout.ctaText && callout.destination && (
                          <Link href={callout.destination}>
                            <a className="tracker-button-all-contact_us-20200317-115043 flex items-center space-x-4 text-blue-dark hover:underline">
                              <span>{callout.ctaText}</span>

                              <IconArrowRight className="!text-blue-dark" />
                            </a>
                          </Link>
                        )}
                      </div>
                    </Disclosure.Panel>
                  </AnimateHeight>
                </>
              )}
            </Disclosure>
          </div>
        )}
        {renderNav && (
          <>
            <NavMain pathname={pathname} navigation={navigation} />
            <NavSub pathname={pathname} navigation={navigation} />
          </>
        )}
        {children}
        {!pathname.includes('/app/') && !pathname.includes('/checkout') && (
          <FooterComponent pathname={pathname} data={footer} />
        )}
      </div>
    </div>
  );
};

export default Main;
