import React, { ReactNode } from 'react';

// either children or both blocks must be set
type Layout2x1Props = {
  block1?: ReactNode;
  block2?: ReactNode;
  children?: ReactNode;
  className?: string;
} & (
  | {
      block1: ReactNode;
      block2: ReactNode;
    }
  | {
      children: ReactNode;
    }
);

const Layout2x1 = ({ block1, block2, children, className }: Layout2x1Props) => (
  <div className={`container px-32 xs:px-128 sm:px-16 md:px-32 lg:px-128 ${className ? className : ''}`}>
    {children && (
      <div className="row mb-64">
        <div className="col-span-12">{children}</div>
      </div>
    )}

    {block1 && block2 && (
      <div className="row">
        <div className="col-span-12 xs:col-span-6">{block1}</div>
        <div className="col-span-12 xs:col-span-6">{block2}</div>
      </div>
    )}
  </div>
);

export default Layout2x1;
