import { format } from 'date-fns';
import Link from 'next/link';
import { useEffect, useState } from 'react';

import type { PublishedFooter } from 'types';
import Logo from 'components/Logo';
import Prefooter from './footer/Prefooter';
import UrlColumn from './footer/UrlColumn';
import SocialMediaColumn from './footer/SocialMediaColumn';
import { getUrlAsTitle } from 'utils/utils';

interface BreadCrumb {
  title: string;
  path: string;
}

const getColumnWidth = (numberOfColumns: number) => {
  if (numberOfColumns > 4) {
    throw new Error(`Footer expects between one and four columns (got ${numberOfColumns})`);
  }

  return 12 / numberOfColumns;
};

export const makeBreadCrumbs = (pathname: string): BreadCrumb[] => {
  const parts = pathname.split('/').filter((part) => part !== '');

  const crumbs = parts.map((path, i) => ({
    title: getUrlAsTitle(path),
    path: '/' + parts.slice(0, i + 1).join('/'),
  }));

  return [{ title: 'Menguin', path: '/' }, ...crumbs];
};

interface Props {
  pathname: string;
  data: PublishedFooter;
}

const Footer = ({ pathname, data }: Props) => {
  const [columnWidth, setColumnWidth] = useState(getColumnWidth(data.layout.length));
  const [breadCrumbs, setBreadCrumbs] = useState(() => makeBreadCrumbs(pathname));

  useEffect(() => {
    setColumnWidth(getColumnWidth(data.layout.length));
  }, [data]);

  useEffect(() => {
    setBreadCrumbs(makeBreadCrumbs(pathname));
  }, [pathname]);

  return (
    <>
      <Prefooter columns={data.prefooter} />

      <div id="footer" className="bg-white">
        <div className="p-section container pb-0">
          <div className="row items-center gap-32">
            <div className="col-span-2 sm:col-span-1">
              <Link href="/">
                <a>
                  <Logo />
                </a>
              </Link>
            </div>

            <div className="col-span-10 sm:col-span-11">
              <ul className="list-slash ml-0 flex list-none gap-4">
                {breadCrumbs.map((x) => (
                  <li key={x.title}>
                    <Link href={x.path}>
                      <a className="font-bold">{x.title}</a>
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <div className="row flex-wrap pt-32 pb-16 lg:pt-64 lg:pb-32">
            {data.layout.map((column) => (
              <div key={column._key} className={`col-span-12 sm:col-span-${columnWidth}`}>
                {column._type === 'urlColumn' ? (
                  <UrlColumn links={column.links} />
                ) : (
                  <SocialMediaColumn links={column.links} />
                )}
              </div>
            ))}
          </div>

          <div className="row">
            <div className="p-section col-span-12 pb-16">
              <p className="text-sm">All Text And Images ©{format(new Date(), 'YYYY')}</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
