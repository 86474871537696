import React from 'react';
import { classNamesBy, mapClassNamesToScreens, getBaseClass } from 'components/Picture/utils';
import { param } from 'utils/utils';
import tw from '../../tailwind.config';

interface breakpoints {
  base?: number | string;
  xs?: number | string;
  sm?: number | string;
  md?: number | string;
  lg?: number | string;
}

type SanityPictureProps = {
  alt: string;
  bg?: string;
  className?: string;
  crop?: boolean;
  focalPointX?: number;
  focalPointY?: number;
  hero?: boolean;
  polaroid?: boolean;
  srcSet?: breakpoints;
  url: string;
};

const SanityPicture = ({
  alt,
  className = '',
  bg = 'fff',
  crop = true,
  focalPointX = 0.5,
  focalPointY = 0.3,
  hero = false,
  polaroid = false,
  srcSet,
  url,
}: SanityPictureProps) => {
  const imgWidths: breakpoints = {
    base: hero ? 343 : srcSet && srcSet.base ? srcSet.base : 284,
    xs: hero ? 299 : srcSet && srcSet.xs ? srcSet.xs : 254,
    sm: hero ? 381 : srcSet && srcSet.sm ? srcSet.sm : 322,
    md: hero ? 436 : srcSet && srcSet.md ? srcSet.md : 358,
    lg: hero ? 580 : srcSet && srcSet.lg ? srcSet.lg : 376,
  };
  const polaroidClass = 'polaroid';

  const screens: breakpoints = tw.theme.screens;
  const chunkedClasses = classNamesBy(className, 'aspect-ratio');
  const aspectRatios: breakpoints = mapClassNamesToScreens(screens, chunkedClasses.match, 'aspect-ratio-7by5');

  const hasValidAr = chunkedClasses.match.reduce((acc, val) => {
    const aspectRatio = val.split('-').slice(-1)[0].replace('by', ':');
    return aspectRatio && aspectRatio.replace(':', 'by') in tw.theme.aspectRatios ? acc : false;
  }, true);

  const showWarnings = !hasValidAr && process.env.ENVIRONMENT !== 'production';

  if (showWarnings)
    console.error(
      `Image uses an unsupported aspect ratio. Either edit the image to match one of the ratios already defined in the Tailwind theme file or define a new one.`
    );

  const baseClass = getBaseClass(chunkedClasses.match.join(' '), 'aspect-ratio', 'aspect-ratio-7by5');
  const classWithBase = [...new Set([...chunkedClasses.match, baseClass])];

  return (
    <div className={`block w-full ${polaroid ? polaroidClass : ''} ${chunkedClasses.noMatch?.join(' ')}`}>
      <div className={classWithBase.join(' ')}>
        <picture className={`inline h-auto w-auto`}>
          {Object.keys(aspectRatios)
            .reverse()
            .map((elm) => {
              const srcSet: DynamicObject = {
                fm: 'jpg',
                crop: 'focalpoint',
                'fp-x': focalPointX,
                'fp-y': focalPointY,
                ar: aspectRatios[elm as keyof breakpoints],
                w: imgWidths[elm as keyof breakpoints],
                auto: 'format',
                bg,
                dpr: '1 1x',
              };

              if (srcSet.ar === '2:1') {
                srcSet.h = Number(imgWidths[elm as keyof breakpoints]) / 2;
              }

              if (crop) {
                srcSet.fit = 'crop';
              }

              const srcSetString = `${url}?${param(srcSet)}`;
              const srcSet2String = `${url}?${param({
                ...srcSet,
                dpr: '2 2x',
              })}`;

              return (
                <source
                  key={elm}
                  media={elm === 'base' ? undefined : `(min-width: ${screens[elm as keyof breakpoints]})`}
                  srcSet={`${srcSetString}, ${srcSet2String}`}
                />
              );
            })}
          <img className={`block h-auto w-full max-w-full`} loading="lazy" alt={alt} />
        </picture>
      </div>
    </div>
  );
};

export default SanityPicture;
