import React from 'react';

type VideoProps = {
  url: string;
  videotype: string;
  title: string | undefined;
};

const Video = ({ url, videotype, title }: VideoProps) => (
  <>
    {videotype === 'vimeo' ? (
      <div className="relative pt-[56.25%]">
        <iframe
          title={title}
          src={url}
          frameBorder="0"
          allow="autoplay; fullscreen; picture-in-picture"
          allowFullScreen
          className="absolute top-0 left-0 h-full w-full"
        />
      </div>
    ) : (
      <figure className="aspect-w-16 aspect-h-9">
        <iframe
          title={title}
          src={url}
          className=""
          loading="lazy"
          allowFullScreen={true}
          style={{ border: 0 }}
          sandbox="allow-scripts allow-same-origin allow-popups allow-presentation"
        />
      </figure>
    )}
  </>
);

export default Video;
