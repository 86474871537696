import React from 'react';

interface HTODisclaimerProps {
  textOnly?: boolean;
}

type Props = React.FC<React.HTMLProps<HTMLDivElement> & HTODisclaimerProps>;

const HTODisclaimer: Props = ({ className, textOnly, ...props }) => (
  <>
    {textOnly ? (
      <p {...props} className={`text-xs text-gray-dark ${className ? className : ''}`}>
        <sup>*</sup>Home Try-On garments subject to availability
      </p>
    ) : (
      <div {...props} className={`row container pb-32 ${className ? className : ''}`}>
        <div className="col-span-12 lg:col-span-11 lg:col-start-2">
          <p className="text-xs text-gray-dark">
            <sup>*</sup>Home Try-On garments subject to availability
          </p>
        </div>
      </div>
    )}
  </>
);
export default HTODisclaimer;
