import Link from 'next/link';
import { Item } from 'types';
import { getImageUrl, getItemUrl } from 'utils/utils';

export type ProductCardProps = {
  item: Item;
  image: null | {
    alt?: string;
    url: string;
  };
};

export const ProductCard = (props: ProductCardProps) => {
  const name = props.item.displayName ?? props.item.display_name ?? '';
  const url = getItemUrl(props.item);

  const image = {
    url: getImageUrl(props.item, 'PDP_Hero'),
    alt: `A product image for the ${name}`,
    ...(props.image ?? {}),
  };

  return (
    <div className="flex-1 border border-gray-light p-4">
      <Link href={url}>
        <a className="block transition hover:bg-white hover:shadow-3">
          <img style={{ display: 'block' }} loading="lazy" src={image.url} alt={image.alt} />

          <h3 className="text-h5 p-16">{name}</h3>
        </a>
      </Link>
    </div>
  );
};
