import React from 'react';
import { Disclosure } from '@headlessui/react';
import IconTriangleDown from 'components/IconTriangleDown';
import AnimateHeight from 'components/AnimateHeight';

interface FaqItemProps {
  question: string;
  trackerClass: string;
}

type Props = React.FC<React.HTMLProps<HTMLDivElement> & FaqItemProps>;

const FaqItem: Props = ({ question, className, children, trackerClass, ...props }) => {
  return (
    <Disclosure as="div" className="border-b border-gray-light">
      {({ open }) => (
        <>
          <Disclosure.Button className={`group flex w-full items-center justify-between py-16 ${trackerClass}`}>
            <p className="flex-grow pr-16 text-left text-blue-dark group-hover:text-black">{question}</p>

            <IconTriangleDown
              className={`flex-shrink-0 fill-current transition  duration-150 group-hover:text-blue-dark ${
                open ? '' : 'rotate-90 transform'
              }`}
            />
          </Disclosure.Button>

          <AnimateHeight open={open}>
            <Disclosure.Panel static className="ml-32 space-y-16 pb-32">
              {children}
            </Disclosure.Panel>
          </AnimateHeight>
        </>
      )}
    </Disclosure>
  );
};

export default FaqItem;
