import React from 'react';
import bugsnag from '@bugsnag/js';
import bugsnagReact from '@bugsnag/plugin-react';
import getConfig from 'next/config';
const config = getConfig();

const bugsnagClient = bugsnag({
  apiKey: config
    ? config.serverRuntimeConfig.BUGSNAG_API_KEY || config.publicRuntimeConfig.BUGSNAG_API_KEY
    : process.env.BUGSNAG_CLIENT_API_KEY,
  releaseStage: process.env.ENVIRONMENT,
  notifyReleaseStages: ['qa', 'stage', 'production'],
  ...(process.env.ENVIRONMENT === 'local' && { logger: null }),
});
bugsnagClient.use(bugsnagReact, React);

export default bugsnagClient;
