import React from 'react';

interface LogoProps {}

type Props = React.FC<React.HTMLAttributes<HTMLOrSVGElement> & LogoProps>;

const Logo: Props = ({ className, ...props }) => {
  return (
    <svg
      viewBox="0 0 1198.25 1198.25"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      className={`h-40 w-40 fill-current text-black ${className ? className : ''}`}
    >
      <title>Menguin Logo</title>
      <path d="M1198.26 1198.25H0V0h1198.26zM80.67 1117.59h1036.92V80.67H80.67z" />
      <path d="M600.7 614.96L361.4 338.89h-25.54v520.47h78.58V512.79l182.31 217.69h3.96L650 671.87l-49.15-57.09-.15.18zM837.11 338.89L666.12 538.56l47.2 58.03 70.49-83.8v346.57h78.58V339.15l-25.28-.26z" />
    </svg>
  );
};

export default Logo;
