import { SanityKeyed, SocialMediaIcon } from '../../../../sanity/schema-types';
import SocialMediaLink from './SocialMediaLink';

type Props = {
  links: SanityKeyed<Required<SocialMediaIcon>>[];
};

const SocialMediaColumn = ({ links }: Props) => {
  return (
    <ul className="ml-0 mb-32 flex list-none gap-16">
      {links.map((link) => (
        <li key={link._key}>
          <SocialMediaLink {...link} />
        </li>
      ))}
    </ul>
  );
};

export default SocialMediaColumn;
