import React, { ReactElement, ReactNode } from 'react';

type DoubleHeaderProps = {
  className?: string;
  hero?: boolean;
  largeTitle: ReactNode | string;
  smallTitle: ReactNode | string;
};

const isH1Element = (node: ReactNode): node is ReactElement<HTMLHeadingElement> => {
  if (typeof node !== 'object' || node === null || Array.isArray(node)) {
    return false;
  }

  return 'type' in node && node.type === 'h1';
};

const DoubleHeader = ({ className = '', hero, largeTitle, smallTitle }: DoubleHeaderProps) => {
  const smallClassNames =
    'text-h5-display flex items-center mb-8 before:bg-black before:h-1 before:w-64 before:block before:mr-16';
  const largeClassNames = hero ? 'text-h1 block' : 'text-h2 block';

  return (
    <span className={`block ${className}`}>
      <div className="grid">
        {isH1Element(largeTitle) ? (
          <>
            <span className={`${largeClassNames} row-start-2`}>{largeTitle}</span>
            <span className={smallClassNames}>{smallTitle}</span>
          </>
        ) : (
          <>
            <span className={smallClassNames}>{smallTitle}</span>
            <span className={largeClassNames}>{largeTitle}</span>
          </>
        )}
      </div>
    </span>
  );
};

export default DoubleHeader;
