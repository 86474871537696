import React, { PropsWithChildren } from 'react';

import SanityPicture from 'components/SanityPicture';

export type SwatchesCardProps = PropsWithChildren<{
  image: SimpleImage;
  items: {
    image: SimpleImage;
  }[];
}>;

const SwatchesCard = ({ children, image, items }: SwatchesCardProps) => (
  <div className="my-116 container bg-panel-9/12-to-left">
    <div className="row pb-128">
      <div className="hidden md:col-span-1 md:block" />

      <div className="col-span-8 xs:col-span-4">
        <SanityPicture className="-mt-64 aspect-ratio-5by7" alt={image.alt || ''} url={image.url} />
      </div>

      <div className="col-span-4 xs:hidden" />

      <div className="col-span-2 xs:col-span-1" />

      <div className="col-span-10 space-y-32 xs:col-span-6 lg:col-span-5">
        <div className="-mt-32 flex gap-16">
          {items.map((swatch, i) => (
            <img
              key={`swatch-list-item-${i}`}
              width="75"
              style={{ display: 'block', aspectRatio: '75/92' }}
              alt={swatch.image.alt}
              src={`${swatch.image.url}?w=75`}
              loading="lazy"
            />
          ))}
        </div>

        <div>{children}</div>
      </div>
    </div>
  </div>
);

export default SwatchesCard;
