import { useEffect, useState } from 'react';
import { PublishedFooter } from 'types';
import { PortableText } from '../../../components/sanity/serializers';
import { urlFor } from '../../../../lib/sanity';
import { SanityBlock, SanityKeyed } from '../../../../sanity/schema-types';

type PrefooterIcon = {
  url: string;
  alt: string;
};

type PrefooterColumn = {
  heading: string;
  icon: PrefooterIcon;
  content: SanityKeyed<SanityBlock>[];
};

type Props = {
  columns: PublishedFooter['prefooter'];
};

export const withIconUrl = (prefooter: PublishedFooter['prefooter']): PrefooterColumn[] => {
  return prefooter.map((column) => {
    const asset = urlFor(column.headerIcon.asset);

    return {
      heading: column.headerText,
      icon: {
        url: asset.url(),
        alt: column.headerIcon.alt,
      },
      content: column.content,
    };
  });
};

const getColumnWidth = (numberOfColumns: number) => {
  if (numberOfColumns > 3) {
    throw new Error(`Prefooter expects between one and three columns (got ${numberOfColumns})`);
  }

  return 12 / numberOfColumns;
};

const Prefooter = ({ columns }: Props) => {
  const [columnsWithIcon, setColumnsWithIcon] = useState(() => withIconUrl(columns));
  const [columnWidth, setColumnWidth] = useState(getColumnWidth(columns.length));

  useEffect(() => {
    setColumnsWithIcon(withIconUrl(columns));

    setColumnWidth(getColumnWidth(columns.length));
  }, [columns]);

  return (
    <div className="bg-gray-light">
      <div className="p-section container">
        <div className="row">
          {columnsWithIcon.map((column, i) => (
            <div className={`col-span-12 sm:col-span-${columnWidth} px-32 text-center`} key={`prefooter-column-${i}`}>
              <img
                className="m-auto max-w-[90px] aspect-ratio-1by1"
                src={column.icon.url}
                alt={column.icon.alt}
                loading="lazy"
              />

              <h3 className="text-h4 mb-16">{column.heading}</h3>

              <PortableText content={column.content} />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Prefooter;
