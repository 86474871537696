import Link from 'next/link';
import React, { ReactNode } from 'react';

type NextStepsProps = {
  children?: ReactNode;
  className?: string;
  ctaPageName: string;
  salesCallout1?: ReactNode;
  salesCallout2?: ReactNode;
  header?: string;
  description?: string;
  topButton?: ReactNode;
  bottomButton?: ReactNode;
};

const NextSteps = (props: NextStepsProps) => {
  const {
    children,
    className,
    ctaPageName,
    salesCallout1,
    salesCallout2,
    header,
    description,
    topButton,
    bottomButton,
  } = props;

  const trackerViewCollection = `tracker-cta-${ctaPageName}-next_steps_module-view_collection-20190306-105512`;
  const trackerCreateAccount = `tracker-cta-${ctaPageName}-next_steps_module-create_account-20190306-105512`;

  return (
    <div className={`next_steps_module m-section ${className ? className : ''}`}>
      <div className="container">
        <div className="row items-stretch gap-y-16">
          <div className="col-span-12 flex sm:col-span-4 lg:col-span-6">
            <div className="full-width-buttons w-full space-y-24 bg-white px-32 py-64 xs:px-128 sm:px-16 sm:py-32 md:px-32 lg:px-128 lg:py-64">
              <h2 className="text-h3">{header ? header : 'Get started.'}</h2>

              {children ? (
                children
              ) : (
                <>
                  <p className="mb-16 text-gray-dark md:mb-64">
                    {description
                      ? description
                      : 'Stylish suits and tuxedos delivered straight to your door. See our collection and rent now.'}
                  </p>

                  <div className="space-y-16">
                    {topButton ? (
                      topButton
                    ) : (
                      <Link href="/collection/tuxedos-and-suits">
                        <a className={`btn-info btn ${trackerViewCollection}`}>View Collection</a>
                      </Link>
                    )}

                    {bottomButton ? (
                      bottomButton
                    ) : (
                      <Link href="/app/signup/email">
                        <a className={`btn-default-outline btn ${trackerCreateAccount}`}>Create Account</a>
                      </Link>
                    )}
                  </div>
                </>
              )}
            </div>
          </div>

          <div className="col-span-12 flex xs:col-span-6 sm:col-span-4 lg:col-span-3">{salesCallout1}</div>
          <div className="col-span-12 flex xs:col-span-6 sm:col-span-4 lg:col-span-3">{salesCallout2}</div>
        </div>
      </div>
    </div>
  );
};

export default NextSteps;
